<template>
  <div>
    <!-- adminユーザのみ使用可能 -->
    <div
      v-if="userRole === 'admin'"
      class="create-project-page pb-8 bg-purple-bg"
    >
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓「戻る」ボタン 行↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <button
        class="text-sm mb-8 cursor-pointer text-white"
        @click="() => $router.push('/manage/projects')"
      >
        &lt;&lt;&nbsp;{{ $t('BACK_BTN') }}
      </button>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑「戻る」ボタン 行↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ プロジェクトの編集 ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!--「プロジェクトの編集」タイトル -->
      <section class="text-3xl text-white mb-20 font-semibold">
        {{ $t('PROJECT_EDIT_TITLE') }}
      </section>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓「プロジェクトの編集」タイトルの下全部 ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <ValidationObserver ref="observer">
        <form @submit.prevent="onSubmitForm">
          <section class="project-info">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ プロジェクト ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              class="!mb-0"
              title="PROJECT_GENERAL_SECTION_TITLE"
              :dataSource="form"
              :columns="generalSection"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：プロジェクト画像アップロード 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="project_img"
                value=""
                class="flex items-center"
              >
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="PROJECT_IMG"
                >
                  <div class="flex items-center">
                    <BaseUploader
                      v-model="form.banner"
                      id="project_banner"
                      suggestSizeText=""
                    />
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div v-if="!form.banner">
                      <a
                        class="text-xl font-semibold"
                        target="_blank"
                        download
                        :href="projectDetail.banner_url"
                        >{{ projectDetail.banner_uploaded_name }}</a
                      >
                    </div>
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="project_img"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：プロジェクト画像アップロード 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：一覧表示用画像アップロード 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="project_thumbnail"
                value=""
                class="flex items-center"
              >
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="PROJECT_LISTING_IMG"
                >
                  <div class="flex items-center">
                    <BaseUploader
                      v-model="form.thumbnail"
                      id="project_thumbnail"
                      suggestSizeText=""
                    />
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div v-if="!form.thumbnail">
                      <a
                        class="text-xl font-semibold"
                        target="_blank"
                        download
                        :href="projectDetail.thumbnail_url"
                        >{{ projectDetail.thumbnail_uploaded_name }}</a
                      >
                    </div>
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="project_thumbnail"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：一覧表示用画像アップロード 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：プロジェクト名 (en) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="name">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="PROJECT_NAME"
                >
                  <a-input
                    v-model="form.name"
                    :maxLength="100"
                    :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 100 })"
                  />
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="name"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：プロジェクト名 (en) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="label_name">{{ $t('PROJECT_NAME_LABEL') }}&nbsp;(en)</div>
              <div slot="label_name_ja">{{ $t('PROJECT_NAME_LABEL') }}&nbsp;(ja)</div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：プロジェクト名 (ja) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="name_ja">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="PROJECT_NAME_JAPANESE"
                >
                  <a-input
                    v-model="form.name_ja"
                    :maxLength="100"
                    :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 100 })"
                  />
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="name_ja"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：プロジェクト名 (ja) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：プロジェクト概要 (en) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="description">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="PROJECT_DESCRIPTION"
                >
                  <Editor
                    v-model="form.description"
                    :maxLength="2400"
                  ></Editor>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="description"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：プロジェクト概要 (en) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="label_description">{{ $t('PROJECT_DESCRIPTION_LABEL') }}&nbsp;(en)</div>
              <div slot="label_description_ja">{{ $t('PROJECT_DESCRIPTION_LABEL') }}&nbsp;(ja)</div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：プロジェクト概要 (ja) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="description_ja">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="PROJECT_DESCRIPTION_JAPANESE"
                >
                  <Editor
                    v-model="form.description_ja"
                    :maxLength="2400"
                  ></Editor>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="description_ja"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：プロジェクト概要 (ja) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：チェーン 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="chain">
                <a-select
                  :value="form.chain_id + (form.token_address ? `##${form.token_address}` : '')"
                  @change="onChainChange"
                  class="w-80"
                >
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="chain in chains"
                    :value="chain.value"
                    :key="chain.value"
                  >
                    {{ chain.key }}
                  </a-select-option>
                </a-select>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：チェーン 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：決済 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="credit_payment">
                <a-checkbox
                  v-model="form.credit_payment"
                  >{{ $t('BUTTON_PIMENT_BUY') }}</a-checkbox
                >
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：決済 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓プロジェクト：PROJECT_VAULT_TYPE_LABEL 行↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- <div slot="vault_type"> -->
              <!--   <div class="text-xl font-semibold w-full"> -->
                  <!-- ↓プロジェクト：PROJECT_VAULT_TYPE_LABEL 行の↓ -->
                  <!-- ↓二つのチェックボックス                     ↓ -->
                  <!--
                  <a-radio-group
                    disabled
                    v-model="form.vault_type"
                  >
                    <a-radio :value="0">
                      {{ $t('PROJECT_VAULT_TYPE_MINT') }}
                    </a-radio>
                    <a-radio :value="1"> {{ $t('PROJECT_VAULT_TYPE_EXTERNAL_CONTRACT') }} </a-radio>
                  </a-radio-group>
                  -->
                  <!-- ↑プロジェクト：PROJECT_VAULT_TYPE_LABEL 行の↑ -->
                  <!-- ↑二つのチェックボックス                     ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓プロジェクト：PROJECT_VAULT_TYPE_LABELで      ↓ -->
                  <!-- ↓PROJECT_VAULT_TYPE_EXTERNAL_CONTRACT          ↓ -->
                  <!-- ↓チェックボックス をチェックしたときに表示する ↓ -->
                  <!--
                  <div
                    v-if="form.vault_type"
                    class="mt-4"
                  >
                    <ValidationProvider
                      :rules="{
                        required: true,
                        checkTokenAddress: true,
                      }"
                      v-slot="{ errors }"
                      name="NFT_VAULT_TYPE"
                    >
                      <div class="flex gap-2 items-center">
                        <div class="flex-grow text-base whitespace-nowrap">
                          {{ $t('PROJECT_CONTRACT_ADDRESS_LABEL') }}:
                        </div>
                        !-- +---------+---------+---------+---------+------ --
                        <a-input
                          disabled
                          :placeholder="$t('PROJECT_CONTRACT_ADDRESS_PLACEHOLDER')"
                          v-model="form.contract_address"
                        ></a-input>
                      </div>
                      !-- --+---------+---------+---------+---------+------ --
                      <ErrorMessage
                        id="vault_type"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  -->
                  <!-- ↑プロジェクト：PROJECT_VAULT_TYPE_LABELで      ↑ -->
                  <!-- ↑PROJECT_VAULT_TYPE_EXTERNAL_CONTRACT          ↑ -->
                  <!-- ↑チェックボックス をチェックしたときに表示する ↑ -->
                <!-- </div> -->
              <!-- </div> -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑プロジェクト：PROJECT_VAULT_TYPE_LABEL 行↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：シンボル 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="symbol">
                <a-input
                  v-model="form.symbol"
                  :maxLength="20"
                  :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 20 })"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：シンボル 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：NFT画像アップロード 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="img">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="NFT_IMG"
                >
                  <div class="flex items-center">
                    <div class="flex items-center mr-4">
                      <BaseUploader
                        id="images-zip"
                        v-model="form.images"
                        :shouldLimitFileSize="false"
                        accept="application/x-zip-compressed, application/zip"
                        class=""
                      />
                      <div class="ml-4">*{{ $t('PROJECT_ZIP_ONLY') }}</div>
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div
                      v-if="!form.images"
                      class="truncate flex-1"
                    >
                      <a
                        class="text-xl font-semibold truncate"
                        target="_blank"
                        download
                        :href="projectDetail.nft_images_url"
                        >{{ projectDetail.image_uploaded_name }}</a
                      >
                    </div>
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="nft-image"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：NFT画像アップロード 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：CSVアップロード 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="json"
                class="w-full"
              >
                <div class="flex justify-between w-full">
                  <div class="flex-1 mr-4">
                    <ValidationProvider
                      rules=""
                      v-slot="{ errors }"
                      name="CSV_UPLOAD"
                    >
                      <div class="flex items-center flex-wrap">
                        <div class="flex items-center mr-4">
                          <BaseUploader
                            id="images-json"
                            v-model="form.json"
                            :shouldLimitFileSize="false"
                            accept=".csv"
                          />
                          <div class="ml-4">*{{ $t('PROJECT_CSV_ONLY') }}</div>
                        </div>
                        <!-- +---------+---------+---------+---------+------ -->
                        <div
                          v-if="!form.json"
                          class="flex-1 truncate"
                        >
                          <a
                            class="text-xl font-semibold w-full"
                            target="_blank"
                            download
                            :href="projectDetail.nft_json_url"
                            >{{ projectDetail.json_uploaded_name }}</a
                          >
                        </div>
                      </div>
                       <!-- --+---------+---------+---------+---------+------ -->
                       <ErrorMessage
                        id="nft-json"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓「サンプルダウンロード」テキスト ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a
                    class="text-right underline relative top-4 cursor-pointer text-xs w-max"
                    :href="JSON_SAMPLE_URL"
                    target="_blank"
                    download
                  >
                    {{ $t('PROJECT_SAMPLE_DOWNLOAD_REF') }}
                  </a>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑「サンプルダウンロード」テキスト ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </div>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：CSVアップロード 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：Website 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="website">
                <ValidationProvider
                  rules="required|url"
                  v-slot="{ errors }"
                  name="WEBSITE"
                >
                  <a-input
                    placeholder="https://zaif-ino.com/"
                    v-model="form.website_url"
                  ></a-input>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="Website"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：Website 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：Twitter 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="twitter">
                <ValidationProvider
                  rules="required|url"
                  v-slot="{ errors }"
                  name="TWITTER"
                >
                  <a-input
                    placeholder="https://twitter.com/zaif_ino"
                    v-model="form.twitter_url"
                  ></a-input>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="twitter"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：Twitter 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：オーナーウォレットアドレス 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="address">
                <ValidationProvider
                  rules="required|checkTokenAddress"
                  v-slot="{ errors }"
                  name="OWNER_ADDRESS"
                >
                  <a-input
                    :placeholder="$t('PROJECT_OWNER_ADDRESS_PLACEHOLDER')"
                    v-model="form.owner_address"
                  ></a-input>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="owner_address"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：オーナーウォレットアドレス 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：PROJECT_SALE_TYPES_LABEL 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!--
              <div slot="sale_types">
                <div class="flex gap-8">
                  <a-checkbox
                    @change="form.is_presale_enabled = !form.is_presale_enabled"
                    :checked="form.is_presale_enabled"
                    >{{ $t('PRESALE_TEXT') }}</a-checkbox
                  >
                  !-- ------+---------+---------+---------+---------+------ --
                  <a-checkbox
                    @change="form.is_publicsale_enabled = !form.is_publicsale_enabled"
                    :checked="form.is_publicsale_enabled"
                    >{{ $t('PUBLIC_SALE_TEXT') }}</a-checkbox
                  >
                </div>
              </div>
              -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：PROJECT_SALE_TYPES_LABEL 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト：TOP 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="top">
                <input type="checkbox"
                  v-model="form.recommendation_top"
                >
                {{ $t('PROJECT_DISPLAY_AS_RECOMMENDED_NFT') }}
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プロジェクト：TOP 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ プロジェクト ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ カテゴリ ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_CATEGORY_SECTION_TITLE"
              :dataSource="form"
              :columns="categorySection"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ カテゴリ：カテゴリ選択コンボボックス ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="category">
                <a-select
                  :value="this.category_id"
                  @change="onCategoryChange"
                  class="w-80"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- コンボボックスの右端の▼ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ カテゴリ選択コンボボックスの複数選択肢 ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="category in this.arrayCategories"
                    :value="category.value"
                    :key="category.key"
                  >
                    {{ category.key }}
                  </a-select-option>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ カテゴリ選択コンボボックスの複数選択肢 ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </a-select>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ カテゴリ：カテゴリ選択コンボボックス ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ カテゴリ ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ プロジェクト（漫画家、アーティスト）↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_CREATOR_SECTION_TITLE"
              :dataSource="form"
              :columns="creatorSection"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プロジェクト選択コンボボックス ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="project">
                <a-select
                  :value="this.creator_id"
                  @change="onCreatorChange"
                  class="w-80"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- コンボボックスの右端の▼ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ プロジェクト選択コンボボックスの複数選択肢 ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="creator in this.arrayCreators"
                    :value="creator.value"
                    :key="creator.key"
                  >
                    {{ creator.key }}
                  </a-select-option>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ プロジェクト選択コンボボックスの複数選択肢 ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </a-select>
              </div>
              <!-- ↑ プロジェクト選択コンボボックス ↑ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ プロジェクト（漫画家、アーティスト）↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ シリーズ ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_SERIES_SECTION_TITLE"
              :dataSource="form"
              :columns="seriesSection"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ シリーズ選択コンボボックス 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="series">
                <a-select
                  :value="this.series_id"
                  @change="onSeriesChange"
                  class="w-80"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- コンボボックスの右端の▼  -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ シリーズ選択コンボボックスの複数選択肢 ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="series in this.arraySeries"
                    :value="series.value"
                    :key="series.key"
                  >
                    {{ series.key }}
                  </a-select-option>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ シリーズ選択コンボボックスの複数選択肢 ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </a-select>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ シリーズ選択コンボボックス 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ シリーズ ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ エディション ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_EDITION_SECTION_TITLE"
              :dataSource="form"
              :columns="editionSection"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ エディション選択コンボボックス ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="edition">
                <a-select
                  :value="this.edition_id"
                  @change="onEditionChange"
                  class="w-80"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- コンボボックスの右端の▼ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ エディション選択コンボボックスの複数選択肢 ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="edition in this.arrayEditions"
                    :value="edition.value"
                    :key="edition.key"
                  >
                    {{ edition.key }}
                  </a-select-option>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ エディション選択コンボボックスの複数選択肢 ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </a-select>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ エディション選択コンボボックス ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ エディション ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ 設定 ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_SETTING_SECTION_TITLE"
              :dataSource="form"
              :columns="settingSection"
              class="text-xl font-semibold !mb-0"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 設定：開封機能 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="reveal">
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓ 設定：開封機能「はい／いいえ」チェックボックス ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div class="flex text-xl font-semibold w-full">
                  <a-radio-group v-model="form.can_reveal">
                    <a-radio :value="true"> {{ $t('PROJECT_YES') }} </a-radio>
                    <a-radio :value="false"> {{ $t('PROJECT_NO') }} </a-radio>
                  </a-radio-group>
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑ 設定：開封機能「はい／いいえ」チェックボックス ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓ 設定：開封機能で「はい」チェック時に表示 ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div
                  v-if="form.can_reveal"
                  class="mt-8"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="REVEAL_IMG"
                  >
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ファイルを選ぶボタン -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <BaseUploader
                      id="reveal_thumbnail_uploader"
                      v-model="form.reveal_thumbnail"
                    />
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <ErrorMessage
                      id="reveal_thumbnail"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑ 設定：開封機能で「はい」チェック時に表示 ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 設定：開封機能 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 設定：PROJECT_AFFILIATE_LABEL 行 ↓-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!--<div slot="affiliate">-->
                <!--
                <div class="flex text-xl font-semibold w-full">
                  <a-radio-group v-model="form.is_affiliate_enabled">
                    <a-radio :value="true"> {{ $t('PROJECT_YES') }} </a-radio>
                    <a-radio :value="false"> {{ $t('PROJECT_NO') }} </a-radio>
                  </a-radio-group>
                </div>
                -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓ 設定：PROJECT_AFFILIATE_LABELで「はい」チェック時 ↓ -->
                <!-- ↓ に表示                                            ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!--
                <div
                  v-if="form.is_affiliate_enabled"
                  class="mt-8"
                >
                  <div class="flex">
                    <div class="mr-6">{{ $t('PROJECT_REF_FEE') }}:</div>
                    !-- ----+---------+---------+---------+---------+------ --
                    <div class="ref-fee-input">
                      <ValidationProvider
                        :rules="{
                          required: true,
                          min_value: 0,
                          max_value: 97.8,
                        }"
                        v-slot="{ errors }"
                        name="REF_FEE"
                      >
                        <InputNumberCustom
                          type="text"
                          v-model="form.affiliate_fee_rate"
                          :decimals="2"
                          class="inline-block w-80"
                          :placeholder="$t('PROJECT_REF_FEE_INPUT_PLACEHOLDER')"
                          :error="errors[0]"
                        ></InputNumberCustom>
                      </ValidationProvider>
                    </div>
                  </div>
                  !-- ------+---------+---------+---------+---------+------ --
                  <div class="flex">
                    <div class="mr-6 whitespace-nowrap">{{ $t('PROJECT_INITIAL_REF') }}:</div>
                    !-- ----+---------+---------+---------+---------+------ --
                    <div :class="['flex-grow']">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="AFFILIATE"
                      >
                        <div class="flex-grow items-center">
                          <BaseUploader
                            v-model="form.referral"
                            :shouldLimitFileSize="false"
                            accept=".csv"
                          />
                        </div>
                        !-- +---------+---------+---------+---------+------ --
                        <ErrorMessage :error="errors[0]" />
                      </ValidationProvider>
                    </div>
                  </div>
                  !-- ------+---------+---------+---------+---------+------ --
                  <div class="truncate">
                    <a
                      class="text-xl font-semibold w-full"
                      target="_blank"
                      download
                      :href="projectDetail.affiliate_json_url"
                      >{{ projectDetail.affiliate_json_uploaded_name }}</a
                    >
                    !-- ----+---------+---------+---------+---------+------ --
                    <span
                      class="ml-4"
                      v-if="form.referral"
                      >(OLD)</span
                    >
                  </div>
                </div>
                -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑ 設定：PROJECT_AFFILIATE_LABELで「はい」チェック時 ↑ -->
                <!-- ↑ に表示                                            ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
              <!--</div>-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 設定：PROJECT_AFFILIATE_LABEL 行 ↑-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 設定：プリセール残数 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="presale_remaining">
                <div slot="transfer_unsold_disabled">
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!--  設定：プリセール残数：                 -->
                  <!--    「残りをパブリックセールに移動する」 -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <div class="font-medium text-lg mb-2">
                    {{ $t('PROJECT_REMAINING_ACTION_TEXT') }}
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ 設定：プリセール残数：                     ↓ -->
                  <!-- ↓   「はい／いいえ」チェックボックスと       ↓ -->
                  <!-- ↓   移動するＮＦＴ数                         ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <div class="text-xl font-semibold w-full">
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- 「はい／いいえ」チェックボックス -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <a-radio-group v-model="form.transfer_unsold_disabled">
                      <a-radio :value="false"> {{ $t('PROJECT_YES') }} </a-radio>
                      <a-radio :value="true"> {{ $t('PROJECT_NO') }} </a-radio>
                    </a-radio-group>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↓ 設定：プリセール残数：               ↓ -->
                    <!-- ↓   「はい／いいえ」チェックボックスで ↓ -->
                    <!-- ↓   「はい」がチェック時の表示         ↓ -->
                    <!-- ↓   移動するＮＦＴ数のテキストボックス ↓ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div
                      v-if="!form.transfer_unsold_disabled"
                      class="mt-4"
                    >
                      <ValidationProvider
                        :rules="{
                          required: true,
                          min_value: 1,
                          max_value: totalAmount ? totalAmount : Number.MAX_SAFE_INTEGER,
                        }"
                        v-slot="{ errors }"
                        name="NFT_SALES_VOLUME"
                      >
                        <InputNumberCustom
                          type="text"
                          v-model="form.target"
                          :decimals="0"
                          class="inline-block w-80"
                          :placeholder="$t('PROJECT_COMBINED_TARGET_LABEL')"
                          :error="errors[0]"
                        ></InputNumberCustom>
                      </ValidationProvider>
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↑ 設定：プリセール残数：               ↑ -->
                    <!-- ↑   「はい／いいえ」チェックボックスで ↑ -->
                    <!-- ↑   「はい」がチェック時の表示         ↑ -->
                    <!-- ↑   移動するＮＦＴ数のテキストボックス ↑ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ 設定：プリセール残数：                     ↑ -->
                  <!-- ↑   「はい／いいえ」チェックボックスと       ↑ -->
                  <!-- ↑   移動するＮＦＴ数                         ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </div>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 設定：プリセール残数 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ 設定 ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ プレセール ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_PRESALE_SECTION_TITLE"
              :dataSource="form"
              :columns="presaleSectionFiltered"
              class="!mb-0"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プレセール：開始 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="start"
                class="text-xl font-semibold"
              >
                <div class="flex items-center">
                  <ValidationProvider
                    :rules="{
                      required: !form.is_presale_tba,
                    }"
                    v-slot="{ errors }"
                    name="PRESALE_START_TIME"
                  >
                    <div class="flex items-center">
                      <BaseDatepicker
                        id="presale_start"
                        v-model="form.presale_start_time"
                        :disabled="form.is_presale_tba"
                      />
                      <!-- --+---------+---------+---------+---------+------ -->
                      <div
                        v-if="form.presale_start_time"
                        class="ml-4"
                      >
                        {{ form.presale_start_time | toJST }}
                      </div>
                      <!-- --+---------+---------+---------+---------+------ -->
                      <div class="ml-4">
                        <a-checkbox
                          :checked="form.is_presale_tba"
                          @change="form.is_presale_tba = !form.is_presale_tba"
                          >{{ $t('TBA') }}</a-checkbox
                        >
                      </div>
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <ErrorMessage
                      id="presale_start"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プレセール：開始 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プレセール：終了 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="end"
                class="text-xl font-semibold"
              >
                <div>
                  <ValidationProvider
                    :rules="{
                      required: !form.is_presale_tba,
                    }"
                    v-slot="{ errors }"
                    name="PRESALE_END_TIME"
                  >
                    <div class="flex items-center">
                      <BaseDatepicker
                        id="presale_end"
                        v-model="form.presale_end_time"
                        :disabled="form.is_presale_tba"
                      />
                      <!-- --+---------+---------+---------+---------+------ -->
                      <div
                        v-if="form.presale_end_time"
                        class="ml-4"
                      >
                        {{ form.presale_end_time | toJST }}
                      </div>
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <ErrorMessage
                      id="presale_end"
                      :error="preSaleTimeError ? $t(preSaleTimeError) : errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プレセール：終了 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プレセール：数量 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="amount"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: !Number(form.publicsale_amount),
                    min_value: 0,
                    max_value: 30000,
                  }"
                  v-slot="{ errors }"
                  name="PRESALE_AMOUNT"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.presale_amount"
                    :decimals="0"
                    class="inline-block w-80"
                    placeholder="0 ~ 30000"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プレセール：数量 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 設定：プリセール残数：            ↓ -->
              <!-- ↓「はい／いいえ」チェックボックスで ↓ -->
              <!-- ↓「いいえ」チェック時に表示         ↓ -->
              <!-- ↓ プレセール：ターゲット 行         ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="presale_target"
                class="text-xl font-semibold"
                v-if="form.transfer_unsold_disabled"
              >
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                    max_value: form.presale_amount,
                  }"
                  v-slot="{ errors }"
                  name="PRESALE_TARGET"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.presale_target"
                    :decimals="0"
                    class="inline-block w-80"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 設定：プリセール残数：            ↑ -->
              <!-- ↑「はい／いいえ」チェックボックスで ↑ -->
              <!-- ↑「いいえ」チェック時に表示         ↑ -->
              <!-- ↑ プレセール：ターゲット 行         ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プレセール：価格 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="price"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: !form.is_presale_price_tba,
                  }"
                  v-slot="{ errors }"
                  name="PRESALE_PRICE"
                >
                  <div class="flex items-center">
                    <InputNumberCustom
                      type="text"
                      v-model="form.presale_price"
                      :decimals="18"
                      class="inline-block w-80"
                      :disabled="form.is_presale_price_tba"
                      :error="errors[0]"
                      placeholder="0 ~"
                    ></InputNumberCustom>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div class="ml-2">
                      {{ symbol }}
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div class="ml-4">
                      <a-checkbox
                        :checked="form.is_presale_price_tba"
                        @change="form.is_presale_price_tba = !form.is_presale_price_tba"
                        >{{ $t('TBA') }}</a-checkbox
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プレセール：価格 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プレセール：ホワイトリスト 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="whitelist"
                class="w-full"
              >
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="WHITELIST"
                >
                  <div class="flex justify-between">
                    <div class="flex flex-1 items-center mr-4">
                      <BaseUploader
                        id="whitelist-json"
                        v-model="form.whitelist"
                        :shouldLimitFileSize="false"
                        accept=".csv"
                      />
                      <!-- --+---------+---------+---------+---------+------ -->
                      <div
                        v-if="!form.whitelist"
                        class="flex-1 truncate ml-4"
                      >
                        <a
                          class="text-xl font-semibold"
                          target="_blank"
                          download
                          :href="projectDetail.presale_whitelist_url"
                          >{{ projectDetail.whitelist_uploaded_name }}</a
                        >
                      </div>
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <a
                      class="text-right underline relative top-4 cursor-pointer text-xs w-max"
                      :href="WHITELIST_SAMPLE_URL"
                      target="_blank"
                      download
                    >
                      {{ $t('PROJECT_SAMPLE_DOWNLOAD_REF') }}
                    </a>
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="whitelist"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プレセール：ホワイトリスト 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ プレセール：最大購入可能数 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="max_purchasability"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                  }"
                  v-slot="{ errors }"
                  name="PRESALE_MAX_PURCHASABILITY"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.presale_max_purchase"
                    :decimals="0"
                    class="inline-block w-80"
                    :error="errors[0]"
                    placeholder="0 ~"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ プレセール：最大購入可能数 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ プレセール ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ 現在時刻 ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_PRESALE_RESULT_SECTION_TITLE"
              :columns="presaleStatistics"
              :dataSource="form"
              class="!mb-0"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 現在時刻：販売済 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="sold"
                class="text-xl font-semibold"
              >
                -
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 現在時刻：販売済 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 現在時刻：収益 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="revenue"
                class="text-xl font-semibold"
              >
                -
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 現在時刻：収益 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 現在時刻：利用可能 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="available"
                class="text-xl font-semibold"
              >
                -
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 現在時刻：利用可能 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ 現在時刻 ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- -+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ パブリックセール ↓ -->
            <!-- -+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_PUBSALE_SECTION_TITLE"
              :dataSource="form"
              :columns="publicSaleSectionFiltered"
              class="!mb-0"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ パブリックセール：開始 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="start"
                class="text-xl font-semibold"
              >
                <div class="flex items-center">
                  <ValidationProvider
                    :rules="{ required: !form.is_publicsale_tba }"
                    v-slot="{ errors }"
                    name="PUBLIC_START_TIME"
                  >
                    <div class="flex items-center">
                      <BaseDatepicker
                        id="public_start"
                        v-model="form.publicsale_start_time"
                        :disabled="form.is_publicsale_tba"
                      />
                      <!-- --+---------+---------+---------+---------+------ -->
                      <div
                        v-if="form.publicsale_start_time"
                        class="ml-4"
                      >
                        {{ form.publicsale_start_time | toJST }}
                      </div>
                      <!-- --+---------+---------+---------+---------+------ -->
                      <div class="ml-4">
                        <a-checkbox
                          :checked="form.is_publicsale_tba"
                          @change="form.is_publicsale_tba = !form.is_publicsale_tba"
                          >{{ $t('TBA') }}</a-checkbox
                        >
                      </div>
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <ErrorMessage
                      id="public_start"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ パブリックセール：開始 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ パブリックセール：終了 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="end"
                class="text-xl font-semibold"
              >
                <div class="flex items-center">
                  <ValidationProvider
                    :rules="{ required: !form.is_publicsale_tba }"
                    v-slot="{ errors }"
                    name="PUBLIC_END_TIME"
                  >
                    <div class="flex items-center">
                      <BaseDatepicker
                        id="public_end"
                        v-model="form.publicsale_end_time"
                        :disabled="form.is_publicsale_tba"
                      />
                      <!-- --+---------+---------+---------+---------+------ -->
                      <div
                        v-if="form.publicsale_end_time"
                        class="ml-4"
                      >
                        {{ form.publicsale_end_time | toJST }}
                      </div>
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <ErrorMessage
                      id="public_start"
                      :error="publicSaleTimeError ? $t(publicSaleTimeError) : errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ パブリックセール：終了 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ パブリックセール：数量 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="amount"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: !Number(form.presale_amount),
                    min_value: 0,
                    max_value: 30000,
                  }"
                  v-slot="{ errors }"
                  name="AMOUNT"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.publicsale_amount"
                    :decimals="0"
                    class="inline-block w-80"
                    placeholder="0 ~ 30000"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ パブリックセール：数量 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 設定：プレセール残数              ↓ -->
              <!-- ↓「はい／いいえ」チェックボックスで ↓ -->
              <!-- ↓「いいえ」チェック時に表示         ↓ -->
              <!-- ↓ パブリックセール：ターゲット 行   ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="publicsale_target"
                class="text-xl font-semibold"
                v-if="form.transfer_unsold_disabled"
              >
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                    max_value: form.publicsale_amount,
                  }"
                  v-slot="{ errors }"
                  name="PUBLIC_SALE_TARGET"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.publicsale_target"
                    :decimals="0"
                    class="inline-block w-80"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 設定：プレセール残数              ↑ -->
              <!-- ↑「はい／いいえ」チェックボックスで ↑ -->
              <!-- ↑「いいえ」チェック時に表示         ↑ -->
              <!-- ↑ パブリックセール：ターゲット 行   ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ パブリックセール：価格 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="price"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: !form.is_publicsale_price_tba,
                  }"
                  v-slot="{ errors }"
                  name="PRICE"
                >
                  <div class="flex items-center">
                    <InputNumberCustom
                      type="text"
                      v-model="form.publicsale_price"
                      :decimals="18"
                      placeholder="0 ~"
                      class="inline-block w-80"
                      :disabled="form.is_publicsale_price_tba"
                      :error="errors[0]"
                    ></InputNumberCustom>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div class="ml-2">
                      {{ symbol }}
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div class="ml-4">
                      <a-checkbox
                        :checked="form.is_publicsale_price_tba"
                        @change="form.is_publicsale_price_tba = !form.is_publicsale_price_tba"
                        >{{ $t('TBA') }}</a-checkbox
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ パブリックセール：価格 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ パブリックセール：最大購入可能数 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="max_purchasability"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                  }"
                  v-slot="{ errors }"
                  name="PUBLIC_MAX_PURCHASABILITY"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.publicsale_max_purchase"
                    :decimals="0"
                    placeholder="0 ~"
                    class="inline-block w-80"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ パブリックセール：最大購入可能数 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ パブリックセール ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ 現在時刻（パブリックセール直下）↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_PUBSALE_RESULT_SECTION_TITLE"
              :dataSource="form"
              :columns="publicSaleStatistics"
              class="!mb-0"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 現在時刻（パブリックセール直下）：販売済 ↓-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="sold"
                class="text-xl font-semibold"
              >
                -
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 現在時刻（パブリックセール直下）：販売済 ↑-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 現在時刻（パブリックセール直下）：利益 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="revenue"
                class="text-xl font-semibold"
              >
                -
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 現在時刻（パブリックセール直下）：利益 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 現在時刻（パブリックセール直下）：利用可能 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="available"
                class="text-xl font-semibold"
              >
                -
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 現在時刻（パブリックセール直下）：利用可能 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ 現在時刻（パブリックセール直下）↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ 費用システム ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_FEE_SECTION_TITLE"
              :dataSource="form"
              :columns="feeSection"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 費用システム：Admin手数料 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="fee"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="FEE"
                  :rules="{
                    required: true,
                    max_value: 100,
                  }"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.fee"
                    :decimals="2"
                    inputClass="ratio-input"
                    class="inline-block w-32"
                    placeholder="2.0"
                  ></InputNumberCustom
                  >&nbsp;%
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="presale_end"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 費用システム：Admin手数料 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 費用システム：Referral Fee 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="dev_fee_rate"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="PROJECT_REFERRAL_FEE_LABEL"
                  :rules="{
                    required: true,
                    max_value: 100,
                  }"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.dev_fee_rate"
                    :decimals="2"
                    inputClass="ratio-input"
                    class="inline-block w-32"
                    placeholder="0.2"
                  ></InputNumberCustom
                  >&nbsp;%
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="presale_end"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 費用システム：Referral Fee 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ 費用システム ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ その他 ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              title="PROJECT_ETCETRA_SECTION_TITLE"
              :dataSource="form"
              :columns="etcetraSection"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ その他：郵送情報入力 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="shipping_form">
                <a-checkbox
                  v-model="form.shipping_form"
                  >{{ $t('PROJECT_SHIPPING_INFORMATION') }}</a-checkbox
                >
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ その他：郵送情報入力 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ その他：stores購入ボタン 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="stores_btn">
                <a-checkbox
                  v-model="form.stores_btn"
                  >{{ $t('PROJECT_STORES_BUY') }}</a-checkbox
                >
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ その他：stores購入ボタン 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ その他：stores購入ボタンリンク 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="stores_url" v-if="this.form.stores_btn">
                <a-input
                  v-model="form.stores_url"
                  :maxLength="200"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="stores_url" v-else>
                <a-input
                  :maxLength="200" disabled
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ その他：stores購入ボタンリンク 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ その他：引き換えモーダル 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="exchange_wallet_info">
                <a-checkbox
                  v-model="form.exchange_wallet_info"
                  >{{ $t('EXCHANGE_WALLET_INFORMATION') }}</a-checkbox
                >
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ その他：引き換えモーダル 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ その他：クレジットカードで購入する際のリンク 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="creditcard_url">
                <a-input
                  v-model="form.creditcard_url"
                  :maxLength="200"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ その他：クレジットカードで購入する際のリンク 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ その他：CICC送金  行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="zaiftx_form">
                <a-checkbox
                  v-model="form.zaiftx_form"
                  >{{ $t('ENABLED_CICC_REMITTANCE') }}</a-checkbox
                >
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ その他：CICC送金  行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ その他 ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </section>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓「保存」ボタン ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="w-full text-center bg-purple-bg py-8">
            <button
              class="bg-primary text-white hover:opacity-60 text-2xl font-semibold py-2 px-12 rounded-xl"
              type="submit"
              :disabled="loading"
            >
              {{ $t('PROJECT_SAVE_BTN') }}
            </button>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑「保存」ボタン ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </form>
      </ValidationObserver>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑「プロジェクトの編集」タイトルの下全部 ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ プロジェクトの編集 ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓「ユーザーロールが有効ではありませ」メッセージ ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div
      v-else
      class="mt-20 text-2xl font-bold text-center"
    >
      {{ $t('PROJECT_INVALID_ROLE_NOTIFICATION') }}
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑「ユーザーロールが有効ではありませ」メッセージ ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import InputNumberCustom from '@/components/InputNumberCustom.vue';
import ProjectViewSection from '@/components/project-management/ProjectViewSection';
import BaseUploader from '@/components/common/BaseUploader.vue';
import BaseDatepicker from '@/components/common/BaseDatepicker.vue';
import Editor from '@/components/common/Editor.vue';
// ------+---------+---------+---------+---------+---------+---------+---------E
import { CHAIN_INFO, SUPPORTED_CHAIN_IDS, TOKEN_SYMBOLS } from '@/constants/chains.constant';
import { CHAIN_ID, JSON_SAMPLE_URL, WHITELIST_SAMPLE_URL } from '@/env';
import ErrorMessage from '@/components/common/ErrorMessage.vue';
import { compare, BigNumber } from '@/utils/bignumber.util';
import { editProject } from '@/services';
import { getCategories } from '@/services/categories';
import UtilNotify from '@/utils/notify.util';
import { mapGetters, mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
// ------+---------+---------+---------+---------+---------+---------+---------E
const generalSection = [
  {
    key: 'project_img',
    title: 'PROJECT_IMG_UPLOAD_LABEL', // 「プロジェクト画像アップロード」
    scopedSlots: { value: 'project_img' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'project_thumbnail',
    title: 'PROJECT_LISTING_IMG_LABEL', // 「一覧表示用画像アップロード」
    scopedSlots: { value: 'project_thumbnail' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'name',
    title: 'PROJECT_NAME_LABEL', // 「プロジェクト名」
    scopedSlots: { value: 'name', title: 'label_name' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'name_ja',
    title: 'PROJECT_NAME_LABEL', // 「プロジェクト名」
    scopedSlots: { value: 'name_ja', title: 'label_name_ja' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'description',
    title: 'PROJECT_DESCRIPTION_LABEL', // 「プロジェクト概要」
    scopedSlots: { value: 'description', title: 'label_description' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'description_ja',
    title: 'PROJECT_DESCRIPTION_LABEL', // 「プロジェクト概要」
    scopedSlots: { value: 'description_ja', title: 'label_description_ja' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'chain',
    title: 'PROJECT_CHAIN_LABEL', // 「チェーン」
    scopedSlots: { value: 'chain' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'credit_payment',
    title: 'PROJECT_PAYMENT_LABEL', // 「決済」
    scopedSlots: { value: 'credit_payment' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'symbol',
    title: 'PROJECT_SYMBOL_LABEL',  // 「シンボル」
    scopedSlots: { value: 'symbol' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'img',
    title: 'PROJECT_NFT_IMG_UPLOAD_LABEL', // 「NFT画像アップロード」
    scopedSlots: {
      value: 'img',
    },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'json',
    title: 'PROJECT_CSV_UPLOAD_LABEL', // 「CSVアップロード」
    scopedSlots: {
      value: 'json',
    },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'website',
    title: 'PROJECT_WEBSITE_LABEL', // 「Website」
    scopedSlots: {
      value: 'website',
    },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'twitter',
    title: 'PROJECT_TWITTER_LABEL', // 「Twitter」
    scopedSlots: { value: 'twitter' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'address',
    title: 'PROJECT_OWNER_ADDRESS_LABEL', // 「オーナーウォレットアドレス」
    scopedSlots: { value: 'address' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // プロジェクトの下位項目「TOP」
  {
    key: 'top',
    title: 'PROJECT_TOP_LABEL', // 「TOP」
    scopedSlots: { value: 'top' },
  },
]; // End of const generalSection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
// ↓ カテゴリ」セクションの行 ↓
// ------+---------+---------+---------+---------+---------+---------+---------E
const categorySection = [
  {
    key: 'category',
    title: 'CATEGORY_CATEGORY_LABEL',
    scopedSlots: { value: 'category' },
  },
]; // End of const categorySection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
// ↑ カテゴリ」セクションの行 ↑
// ------+---------+---------+---------+---------+---------+---------+---------E
// ------+---------+---------+---------+---------+---------+---------+---------E
// ↓「プロジェクト（漫画家、アーティスト）」セクションの行 ↓
// ------+---------+---------+---------+---------+---------+---------+---------E
const creatorSection = [
  {
    key: 'project',
    title: 'PROJECT_PROJECT_LABEL',
    scopedSlots: { value: 'project' },
  },
]; // End of const creatorSection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
// ↑「プロジェクト（漫画家、アーティスト）」セクションの行 ↑
// ------+---------+---------+---------+---------+---------+---------+---------E
// ------+---------+---------+---------+---------+---------+---------+---------E
// ↓「シリーズ」セクションの行 ↓
// ------+---------+---------+---------+---------+---------+---------+---------E
const seriesSection = [
  {
    key: 'series',
    title: 'SERIES_SERIES_LABEL',
    scopedSlots: { value: 'series' },
  },
]; // End of const seriesSection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
// ↑「シリーズ」セクションの行 ↑
// ------+---------+---------+---------+---------+---------+---------+---------E
// ------+---------+---------+---------+---------+---------+---------+---------E
// ↓「エディション」セクションの行 ↓
// ------+---------+---------+---------+---------+---------+---------+---------E
const editionSection = [
  {
    key: 'edition',
    title: 'EDITION_EDITION_LABEL',
    scopedSlots: { value: 'edition' },
  },
]; // End of const editionSection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
// ↑「シリーズ」セクションの行 ↑
// ------+---------+---------+---------+---------+---------+---------+---------E
const settingSection = [
  {
    key: 'reveal',
    title: 'PROJECT_REVEAL_LABEL',
    scopedSlots: { value: 'reveal' },
  },
  {
    key: 'presale_remaining',
    title: 'PROJECT_PRESALE_REMAINING_LABEL',
    scopedSlots: { value: 'presale_remaining' },
  },
]; // End of const settingSection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
const presaleSection = [
  {
    key: 'presale_start',
    title: 'PROJECT_PRESALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'presale_end',
    title: 'PROJECT_PRESALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'presale_amount',
    title: 'PROJECT_PRESALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'presale_target',
    title: 'PROJECT_PRESALE_TARGET_LABEL',
    scopedSlots: { value: 'presale_target' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'presale_price',
    title: 'PROJECT_PRESALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'presale_whitelist',
    title: 'PROJECT_PRESALE_WHITELIST_LABEL',
    scopedSlots: { value: 'whitelist' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'presale_max_purchasability',
    title: 'PROJECT_PRESALE_MAX_PURCHASE_LABEL',
    scopedSlots: { value: 'max_purchasability' },
  },
]; // End of const presaleSection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
const presaleStatistics = [
  {
    key: 'presale_sold',
    title: 'PROJECT_PRESALE_SOLD_LABEL',
    scopedSlots: { value: 'sold' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'presale_revenue',
    title: 'PROJECT_PRESALE_REVENUE_LABEL',
    scopedSlots: { value: 'revenue' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'presale_available',
    title: 'PROJECT_PRESALE_AVAILABLE_LABEL',
    scopedSlots: { value: 'available' },
  },
]; // End of const presaleStatistics = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
const publicSaleSection = [
  {
    key: 'public_sale_start',
    title: 'PROJECT_PUBSALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'public_sale_end',
    title: 'PROJECT_PUBSALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'public_sale_amount',
    title: 'PROJECT_PUBSALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'publicsale_target',
    title: 'PROJECT_PUBSALE_TARGET_LABEL',
    scopedSlots: { value: 'publicsale_target' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'public_sale_price',
    title: 'PROJECT_PUBSALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'public_sale_max_purchasability',
    title: 'PROJECT_PUBSALE_MAX_PURCHASE_LABEL',
    scopedSlots: { value: 'max_purchasability' },
  },
]; // End of const publicSaleSection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
const publicSaleStatistics = [
  {
    key: 'public_sale_sold',
    title: 'PROJECT_PUBSALE_SOLD_LABEL',
    scopedSlots: { value: 'sold' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'public_sale_revenue',
    title: 'PROJECT_PUBSALE_REVENUE_LABEL',
    scopedSlots: { value: 'revenue' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'public_sale_available',
    title: 'PROJECT_PUBSALE_AVAILABLE_LABEL',
    scopedSlots: { value: 'available' },
  },
]; // End of const publicSaleStatistics = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
const feeSection = [
  {
    key: 'fee_ratio',
    title: 'PROJECT_FEE_LABEL',
    scopedSlots: { value: 'fee' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'dev_fee_rate',
    title: 'PROJECT_REFERRAL_FEE_LABEL',
    scopedSlots: { value: 'dev_fee_rate' },
  },
]; // End of const feeSection = [...]
// ------+---------+---------+---------+---------+---------+---------+---------E
const etcetraSection = [
  {
    key: 'shipping_form',
    title: 'SHIPPING_FORM_LABEL', // 「郵送情報」
    scopedSlots: { value: 'shipping_form' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'stores_btn',
    title: 'STORES_BTN_LABEL', // 「stores購入」
    scopedSlots: { value: 'stores_btn' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'stores_url',
    title: 'STORES_URL_LABEL',  // 「store URL」
    scopedSlots: { value: 'stores_url' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'exchange_wallet_info',
    title: 'EXCHANGE_WALLET_LABEL', // 「引き換えモーダル」
    scopedSlots: { value: 'exchange_wallet_info' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'creditcard_url',
    title: 'CREDIT_CARD_URL_LABEL',  // 「クレカ購入(piement)URL」
    scopedSlots: { value: 'creditcard_url' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'zaiftx_form',
    title: 'CICC_REMITTANCE', // 「CICC送金」
    scopedSlots: { value: 'zaiftx_form' },
  },
]; // End of 
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'ProjectDetailPage',
  props: {},
  components: {
    Editor,
    ProjectViewSection,
    InputNumberCustom,
    BaseUploader,
    BaseDatepicker,
    ErrorMessage,
  }, // End of components: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      generalSection,
      categorySection,
      creatorSection,
      seriesSection,
      editionSection,
      presaleSection,
      presaleStatistics,
      publicSaleSection,
      publicSaleStatistics,
      feeSection,
      settingSection,
      etcetraSection,
      loading: false,
      // +---------+---------+---------+---------+---------+---------+---------E
      category_id: undefined,
      creator_id: undefined,
      series_id: undefined,
      edition_id: undefined,
      arrayCategories: [],        // カテゴリコンボボックスの選択肢
      objectCategoryIdNameEn: {}, // カテゴリidと英語カテゴリ名を紐づけるオブジェクト
      objectCategoryNameEnId: {}, // 英語カテゴリ名とカテゴリidを紐づけるオブジェクト
      // プロジェクト（漫画家、アーティスト）コンボボックスの選択肢
      arrayCreators: [],
      // プロジェクト（漫画家、アーティスト）idと
      // 英語プロジェクト（漫画家、アーティスト）名を紐づけるオブジェクト
      objectCreatorIdNameEn: {},
      // 英語プロジェクト（漫画家、アーティスト）名と
      // プロジェクト（漫画家、アーティスト）idを紐づけるオブジェクト
      objectCreatorNameEnId: {},
      arraySeries: [],            // シリーズコンボボックスの選択肢
      objectSeriesIdNameEn: {},   // シリーズidと英語シリーズ名を紐づけるオブジェクト     
      objectSeriesNameEnId: {},   // 英語シリーズ名とシリーズidを紐づけるオブジェクト     
      arrayEditions: [],          // エディションコンボボックスの選択肢
      objectEditionIdNameEn: {},  // エディションidと英語シリーズ名を紐づけるオブジェクト     
      objectEditionNameEnId: {},  // 英語エディション名とエディションidを紐づけるオブジェクト     
      // +---------+---------+---------+---------+---------+---------+---------E
      form: {
        owner_address: '',
        banner: null,
        thumbnail: null,
        contract_type: 721,
        name: '',
        name_ja: '',
        description: '',
        description_ja: '',
        chain_id: CHAIN_ID,
        credit_payment: false,
        token_address: null,
        symbol: 'INO',
        images: null,
        json: null,
        website_url: '',
        twitter_url: '',
        recommendation_top: false, // TOPチェックボックスの値
        hierarchy: '',
        presale_start_time: null,
        presale_end_time: null,
        presale_price: '',
        presale_amount: '',
        presale_target: '',
        whitelist: null,
        presale_max_purchase: '',
        publicsale_start_time: null,
        publicsale_end_time: null,
        publicsale_price: '',
        publicsale_amount: '',
        publicsale_target: '',
        publicsale_max_purchase: '',
        fee: '2.0',
        dev_fee_rate: '0.2',
        can_reveal: false,
        reveal_thumbnail: null,
        is_presale_tba: false,
        is_publicsale_tba: false,
        is_presale_price_tba: false,
        is_publicsale_price_tba: false,
        transfer_unsold_disabled: false,
        target: '',
        shipping_form: false,
        stores_btn: false,
        stores_url: '',
        exchange_wallet_info: false,
        creditcard_url: '',
        zaiftx_form: false,
      }, // End of form: {...}
      JSON_SAMPLE_URL,
      WHITELIST_SAMPLE_URL,
    }; // End of return {...}
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapGetters({
      userRole: 'wallet/loggedUserRole',
      account: 'wallet/account',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapState({
      locale: (state) => state?.setting.locale,
      projectDetailMap: (state) => state.projects.projectDetailMap,
      connectedChainId: (state) => state.wallet?.provider?.chainId || -1,
    }),
    presaleSectionFiltered() {
      let result = presaleSection;
      if (!this.form.transfer_unsold_disabled) {
        result = result.filter((el) => el.key !== 'presale_target');
      }
      if (!Number(this.form.presale_amount)) {
        result = result.filter((item) => item.key === 'presale_amount');
      }
      return result;
    }, // End of presaleSectionFiltered() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleSectionFiltered() {
      let result = publicSaleSection;
      if (!this.form.transfer_unsold_disabled) {
        result = result.filter((el) => el.key !== 'publicsale_target');
      }
      if (!Number(this.form.publicsale_amount)) {
        result = result.filter((item) => item.key === 'public_sale_amount');
      }
      return result;
    }, // End of publicSaleSectionFiltered() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    projectDetail() {
      const { id } = this.$route.params;
      console.debug({ id, projectDetailMap: this.projectDetailMap });
      if (id) {
        const data = this.projectDetailMap[id];
        console.debug({ data });
        return data;
      } // End of if (id) {...}
      return {};
    }, // End of projectDetail() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    chains() {
      let chains = [];
      SUPPORTED_CHAIN_IDS.forEach((el) => {
        const chainData = CHAIN_INFO[el];
        // --------+---------+---------+---------+---------+---------+---------E
        chains.push({
          key: `${chainData?.name} - ${chainData.nativeCurrency.symbol}`,
          value: `${el}`,
        });
        // --------+---------+---------+---------+---------+---------+---------E
        if (Array.isArray(chainData.acceptedTokens)) {
          chainData.acceptedTokens.forEach((item) => {
            chains.push({
              key: `${chainData?.name} - ${item.symbol}`,
              value: `${el}##${item.tokenAddress}`,
            });
          }); // End of chainData.acceptedTokens.forEach((item) => {...}
        } // End of if (Array.isArray(chainData.acceptedTokens)) {...}
      }); // End of SUPPORTED_CHAIN_IDS.forEach((el) => {...})
      //alert(`chains=${JSON.stringify(chains)}: chains()`);
      return chains;
    }, // End of chains() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    symbol() {
      return this.form.token_address
        ? TOKEN_SYMBOLS[this.form.chain_id][this.form.token_address]
        : CHAIN_INFO[this.form.chain_id].nativeCurrency.symbol;
      }, // End of symbol() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    isPreSaleTimeInValid() {
      if (this.form.presale_start_time && this.form.presale_end_time) {
        return compare('gte')(this.form.presale_start_time)(this.form.presale_end_time);
      }
      return false;
    }, // End of isPreSaleTimeInValid() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    preSaleTimeError() {
      if (this.isPreSaleTimeInValid) return 'PROJECT_PRESALE_START_TIME_ERROR';
      return '';
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isPublicSaleTimeInValid() {
      if (this.form.publicsale_start_time && this.form.publicsale_end_time) {
        return compare('gte')(this.form.publicsale_start_time)(this.form.publicsale_end_time);
      }
      return false;
    }, // End of isPublicSaleTimeInValid() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    isPublicSaleStartTimeNotAfterPresaleStartTime() {
      if (this.form.publicsale_start_time && this.form.presale_start_time) {
        return compare('lte')(this.form.publicsale_start_time)(this.form.presale_start_time);
      }
      // +---------+---------+---------+---------+---------+---------+---------E
      return false;
    }, // End of isPublicSaleStartTimeNotAfterPresaleStartTime() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    isPublicSaleEndTimeNotAfterPresaleEndTime() {
      if (this.form.publicsale_end_time && this.form.presale_end_time) {
        return compare('lte')(this.form.publicsale_end_time)(this.form.presale_end_time);
      }
      return false;
    }, // End of isPublicSaleEndTimeNotAfterPresaleEndTime() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleTimeError() {
      if (this.isPublicSaleTimeInValid) return 'PROJECT_PUBSALE_START_TIME_ERROR';
      if (this.isPublicSaleStartTimeNotAfterPresaleStartTime) {
        return 'PROJECT_PUBSALE_START_OVERLAP_TIME_ERROR';
      }
      if (this.isPublicSaleEndTimeNotAfterPresaleEndTime) {
        return 'PROJECT_PUBSALE_END_OVERLAP_TIME_ERROR';
      }
      return '';
    }, // End publicSaleTimeError() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    totalAmount() {
      if (this.form.presale_amount || this.form.publicsale_amount) {
        return Number(this.form.presale_amount || 0) + Number(this.form.publicsale_amount || 0);
      }
      return 0;
    }, // End of totalAmount() {...}
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  watch: {
    projectDetail(detail) {
      const clonedFormData = { ...this.form };
      console.debug({ detail });
      if (detail) {
        Object.keys(clonedFormData).forEach((key) => {
          if (!['banner', 'images', 'json', 'reveal_thumbnail', 'whitelist'].includes(key)) {
            if (
              [
                'presale_start_time',
                'publicsale_end_time',
                'publicsale_start_time',
                'presale_end_time',
              ].includes(key)
            ) {
              if (detail[key]) {
                clonedFormData[key] = dayjs(detail[key]).unix() * 1000;
              }
            } else {
              clonedFormData[key] = detail[key];
            } // End of if (...) else {...}
          } // End of if (!['banner', 'images', 'json', ...].includes(key)){...}
        }); // End of Object.keys(clonedFormData).forEach((key) => {...})
        this.form = {
          ...clonedFormData,
        };
        this.form.credit_payment = this.projectDetail.payment === 1;
        this.form.shipping_form = (this.projectDetail.shipping_form === 1);
        this.form.stores_btn = (this.projectDetail.stores_btn === 1);
        this.form.exchange_wallet_info =
                                (this.projectDetail.exchange_wallet_info === 1);
        this.form.zaiftx_form = (this.projectDetail.zaiftx_form === 1);
        // バックエンドから上がってきた情報に対する処理。
        this.form.recommendation_top =
          (this.projectDetail.recommendation_top === 1);
      } // End of if (detail) {...}
    }, // End of projectDetail(detail) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // 'form.is_presale_enabled': function (newVal) {
    //   if (!newVal) {
    //     this.form = {
    //       ...this.form,
    //       presale_start_time: null,
    //       presale_end_time: null,
    //       presale_price: '',
    //       presale_amount: '',
    //       presale_target: '',
    //       whitelist: null,
    //       presale_max_purchase: '',
    //       is_presale_tba: false,
    //       is_presale_price_tba: false,
    //     }; // End of this.form = {...}
    //   } // End of if (!newVal) {...}
    // }, // End of 'form.is_presale_enabled': function (newVal) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // 'form.is_publicsale_enabled': function (newVal) {
    //   if (!newVal) {
    //     this.form = {
    //       ...this.form,
    //       publicsale_start_time: null,
    //       publicsale_end_time: null,
    //       publicsale_price: '',
    //       publicsale_amount: '',
    //       publicsale_target: '',
    //       publicsale_max_purchase: '',
    //       is_publicsale_tba: false,
    //       is_publicsale_price_tba: false,
    //     }; // End of this.form = {...}
    //   } // End of if (!newVal) {...}
    // }, // End of 'form.is_publicsale_enabled': function (newVal) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // 言語が切り替わったら、コンボボックスの選択肢も言語に合わせて切り替える。
    locale: async function() {
      await this.updateComboBoxOptions()
    },
  }, // End of watch: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  async mounted() {
    // alert(`START: mounted()`);
    await this.updateComboBoxOptions()
  }, // End of mounted() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
    created() {
    this.fetchProjectDetailById();
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
     // コンボボックスの選択肢を更新。
     async updateComboBoxOptions() {
      await this.getCategories();
      await this.getCreators();
      await this.getSeries();
      await this.getEditions();
    }, // End of async updateComboBoxOptions() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories からカテゴリを取得する。カテゴリコンボボックスで使う。
    async getCategories() {
      const { arrayObjectOptions: arrayObjectOptions,
              objectIdNameEn: objectIdNameEn,
              objectNameEnId: objectNameEnId,
      } = await this.getFromTableCategories(0, undefined);
      this.arrayCategories = arrayObjectOptions;
      this.objectCategoryIdNameEn = objectIdNameEn;
      this.objectCategoryNameEnId = objectNameEnId;
      // alert(`this.arrayCategories=${JSON.stringify(this.arrayCategories)}: getCategories()`);
      // alert(`this.objectCategoryIdNameEn=${JSON.stringify(this.objectCategoryIdNameEn)}: getCategories()`);
      // alert(`this.objectCategoryNameEnId=${JSON.stringify(this.objectCategoryNameEnId)}: getCategories()`);
    }, // End of async getCategories() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories からプロジェクト（漫画家、アーティスト）を取得する。
    // プロジェクト（漫画家、アーティスト）コンボボックスで使う。
    async getCreators() {
      // if (!this.category_id) {
      //   // カテゴリ指定無し。
      //   this.arrayCreators = [];
      //   this.objectCreatorIdNameEn = {};
      //   this.objectCreatorNameEnId = {};
      //   return;
      // }
      // カテゴリ指定有り。
      const { arrayObjectOptions: arrayObjectOptions,
              objectIdNameEn: objectIdNameEn,
              objectNameEnId: objectNameEnId,
      } = await this.getFromTableCategories(1,
                          this.objectCategoryIdNameEn[this.category_id]
      );
      this.arrayCreators = arrayObjectOptions;
      this.objectCreatorIdNameEn = objectIdNameEn;
      this.objectCreatorNameEnId = objectNameEnId;
      // alert(`this.arrayCreators=${JSON.stringify(this.arrayCreators)}: getCategories()`);
      // alert(`this.objectCreatorIdNameEn=${JSON.stringify(this.objectCreatorIdNameEn)}: getCategories()`);
      // alert(`this.objectCreatorNameEnId=${JSON.stringify(this.objectCreatorNameEnId)}: getCategories()`);
      // alert(`END  : getCreators()`);
    }, // End of async getCreators() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories からシリーズを取得する。
    // シリーズコンボボックスで使う。
    async getSeries() {
      // if (!this.category_id || !this.creator_id) {
      //   // カテゴリ又はクリエイターが指定無し。
      //   this.arraySeries = [];
      //   this.objectSeriesIdNameEn = {};
      //   this.objectSeriesNameEnId = {};
      //   return;
      // }
      // alert(`this.category_id=${this.category_id}: getSeries()`);
      // alert(`categoryName_en=${this.objectCategoryIdNameEn[this.category_id]}: getSeries()`);
      // alert(`this.creator_id=${this.creator_id}: getSeries()`);
      // alert(`creatorName_en=${this.objectCreatorIdNameEn[this.creator_id]}: getSeries()`);
      const { arrayObjectOptions: arrayObjectOptions,
              objectIdNameEn: objectIdNameEn,
              objectNameEnId: objectNameEnId,
      } = await this.getFromTableCategories(
        2,
        this.objectCategoryIdNameEn[this.category_id] + '\\' +
        this.objectCreatorIdNameEn[ this.creator_id]
      );
      this.arraySeries = arrayObjectOptions;
      this.objectSeriesIdNameEn = objectIdNameEn;
      this.objectSeriesNameEnId = objectNameEnId;
      // alert(`this.arraySeries=${JSON.stringify(this.arraySeries)}: getSeries()`);
      // alert(`this.objectSeriesIdNameEn=${JSON.stringify(this.objectSeriesIdNameEn)}: getSeries()`);
      // alert(`this.objectSeriesNameEnId=${JSON.stringify(this.objectSeriesNameEnId)}: getSeries()`);
    }, // End of async getSeries() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories からシリーズを取得する。
    // シリーズコンボボックスで使う。
    async getEditions() {
      // if (!this.category_id || !this.creator_id || !this.series_id) {
      //   this.arrayEditions = [];
      //   this.objectEditionIdNameEn = {};
      //   return;
      // }
      // alert(`this.category_id=${this.category_id}: getEditions()`);
      // alert(`categoryName_en=${this.objectCategoryIdNameEn[this.category_id]}: getEditions()`);
      // alert(`this.creator_id=${this.creator_id}: getEditions()`);
      // alert(`creatorName_en=${this.objectCreatorIdNameEn[this.creator_id]}: getEditions()`);
      // alert(`this.series_id=${this.series_id}: getEditions()`);
      // alert(`seriesName_en=${this.objectSeriesIdNameEn[this.series_id]}: getEditions()`);
      const { arrayObjectOptions: arrayObjectOptions,
              objectIdNameEn: objectIdNameEn,
              objectNameEnId: objectNameEnId,
      } = await this.getFromTableCategories(
        3,
        this.objectCategoryIdNameEn[this.category_id] + '\\' +
        this.objectCreatorIdNameEn[ this.creator_id]  + '\\' +
        this.objectSeriesIdNameEn[  this.series_id]
      );
      this.arrayEditions = arrayObjectOptions;
      this.objectEditionIdNameEn = objectIdNameEn;
      this.objectEditionNameEnId = objectNameEnId;
      // alert(`this.arrayEditions=${JSON.stringify(this.arrayEditions)}: getEditions()`);
      // alert(`this.objectEditionIdNameEn=${JSON.stringify(this.objectEditionIdNameEn)}: getEditions()`);
      // alert(`this.objectEditionNameEnId=${JSON.stringify(this.objectEditionNameEnId)}: getEditions()`);
    }, // End of async getEditions() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories から、カテゴリ、プロジェクト（漫画家、アーティスト）、
    // シリーズ、又は、エディションを取得する。コンボボックスで使う。
    async getFromTableCategories(intType, strHierarchy) {
      // alert(`this.locale=${this.locale}: getFromTableCategories()`);
      // alert(`intType=${intType}, strHierarchy=${strHierarchy}: getFromTableCategories()`);
      const {items: arrayObjectRecordCategories} =
        await getCategories({
          // type  0:カテゴリ、1:プロジェクト（漫画家、アーティスト）
          //       2:シリーズ、3:エディション
          type:  intType,
          hierarchy: strHierarchy,
        }); // End of await getCategories({...})
      // +---------+---------+---------+---------+---------+---------+---------E
      // コンボボックス用のカテゴリのオブジェクト配列を作成。
      //let arrayObject = [];
      let arrayObject = [{key: this.strUndefined(), value: 0}];
      // 同時に id から name_en が得られるオブジェクトも作成。
      let objectIdNameEn = {0: ''}; // 指定無しで、id=0, value=""
      // 同時に name_en から id が得られるオブジェクトも作成。
      let objectNameEnId = {};
      for (const objectRecordCategory of arrayObjectRecordCategories) {
        let objectCategory = {}; // カテゴリのオブジェクト配列の一要素
        // --------+---------+---------+---------+---------+---------+---------E
        const strCategoryName = (
          this.locale === 'en'  ? objectRecordCategory.name_en
                                : objectRecordCategory.name_ja
        );
        // --------+---------+---------+---------+---------+---------+---------E
        objectCategory['key'  ] = strCategoryName;
        objectCategory['value'] = objectRecordCategory.id;
        // --------+---------+---------+---------+---------+---------+---------E
        arrayObject.push(objectCategory);
        objectIdNameEn[objectRecordCategory.id] = objectRecordCategory.name_en;
        objectNameEnId[objectRecordCategory.name_en] = objectRecordCategory.id;
      } // End of for (const objectRecordCategory of ...) {...}
      // +---------+---------+---------+---------+---------+---------+---------E
      // alert(`arrayObjectResult=${JSON.stringify(arrayObjectResult)}: getFromTableCategories()`);
      let objectResult = {};
      objectResult['arrayObjectOptions'] = arrayObject;
      objectResult['objectIdNameEn'    ] = objectIdNameEn;
      objectResult['objectNameEnId'    ] = objectNameEnId;
      return objectResult;
    }, // End of async getFromTableCategories() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E    
    onChainChange(value) {
      const [chainId, tokenAddress] = value.split('##');
      this.form.chain_id = chainId;
      this.form.token_address = tokenAddress || null;
    }, // End of onChainChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // カテゴリのコンボボックスが使われた時に実行する関数。
    async onCategoryChange(value) {
      this.category_id = value;
      // プロジェクト・シリーズ・エディションのコンボボックスの
      // 選択肢を [{key: '--- 指定無し ---', value: 0}] だけにする。
      this.arrayCreators = [{key: this.strUndefined(), value: 0}];
      this.arraySeries   = [{key: this.strUndefined(), value: 0}];
      this.arrayEditions = [{key: this.strUndefined(), value: 0}];
      // サブカテゴリ以下はコンボボックスの選択を「指定なし」にする。
      this.creator_id = 0; // コンボボックスの選択を「指定なし」にする。
      this.series_id  = 0; // コンボボックスの選択を「指定なし」にする。
      this.edition_id = 0; // コンボボックスの選択を「指定なし」にする。
      // hierarchy クリア。
      this.form.hierarchy = undefined;
      if (value == 0) { return } // 「指定無し」選択された場合。
      // カテゴリが選択された。
      // hierarchy 作成。
      this.form.hierarchy = this.objectCategoryIdNameEn[this.category_id];
      // alert(`this.form.hierarchy=${this.form.hierarchy}: onCategoryChange`);
      // プロジェクト（漫画家、アーティスト） 選択肢作成。
      await this.getCreators();
    }, // End of async onCategoryChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // 「プロジェクト（漫画家、アーティスト）」のコンボボックスが使われた時
    // に実行する関数。
    async onCreatorChange(value) {
      this.creator_id = value;
      // シリーズ・エディションのコンボボックスの
      // 選択肢を [{key: '--- 指定無し ---', value: 0}] だけにする。
      this.arraySeries   = [{key: this.strUndefined(), value: 0}];
      this.arrayEditions = [{key: this.strUndefined(), value: 0}];
      // サブカテゴリ以下はコンボボックスの選択を「指定なし」にする。
      this.series_id  = 0; // コンボボックスの選択を「指定なし」にする。
      this.edition_id = 0; // コンボボックスの選択を「指定なし」にする。
      // hierarchy の上位カテゴリ部分まで作成。
      this.form.hierarchy = this.objectCategoryIdNameEn[this.category_id];
      if (value == 0) { return } // 「指定無し」選択された場合。
      // クリエイターが選択された。
      // hierarchy のクリエイター部分追加。
      this.form.hierarchy = this.form.hierarchy + '\\' +
                            this.objectCreatorIdNameEn[ this.creator_id ];
      // alert(`this.form.hierarchy=${this.form.hierarchy}: onCreatorChange`);
      // シリーズ選択肢作成。
      await this.getSeries();
    }, // End of async onCreatorChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // 「シリーズ」のコンボボックスが使われた時に実行する関数。
    async onSeriesChange(value) {
      this.series_id = value;
      // エディションのコンボボックスの
      // 選択肢を [{key: '--- 指定無し ---', value: 0}] だけにする。
      this.arrayEditions = [{key: this.strUndefined(), value: 0}];
      // サブカテゴリ以下はコンボボックスの選択を「指定なし」にする。
      this.edition_id = 0; // コンボボックスの選択を「指定なし」にする。
      // hierarchy の上位カテゴリ部分まで作成。
      this.form.hierarchy =
        this.objectCategoryIdNameEn[this.category_id] + '\\' +
        this.objectCreatorIdNameEn[ this.creator_id ];
      if (value == 0) { return } // 「指定無し」選択された場合。
      // シリーズが選択された。
      // hierarchy 作成。
      this.form.hierarchy = this.form.hierarchy + '\\' +
                            this.objectSeriesIdNameEn[  this.series_id  ];
    // (`this.form.hierarchy=${this.form.hierarchy}: onSeriesChange`);
      // エディション選択肢作成。
      await this.getEditions();
    }, // End of async onSeriesChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // 「エディション」のコンボボックスが使われた時に実行する関数。
    async onEditionChange(value) {
      this.edition_id = value;
      // hierarchy の上位カテゴリ部分まで作成。
      this.form.hierarchy =
        this.objectCategoryIdNameEn[this.category_id] + '\\' +
        this.objectCreatorIdNameEn[ this.creator_id ] + '\\' +
        this.objectSeriesIdNameEn[  this.series_id  ];
      if (value == 0) { return } // 「指定無し」選択された場合。
      // エディション選択された。
      // hierarchy 作成。
      this.form.hierarchy = this.form.hierarchy + '\\' +
                            this.objectEditionIdNameEn[ this.edition_id ];
     // alert(`this.form.hierarchy=${this.form.hierarchy}: onEditionChange`);
    }, // End of async onEditionChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // コンボボックス選択肢用の「--- Undefined ---」「--- 指定無し ---」。
    // 英語と日本語で切り替える。
    strUndefined() {
      let result = (this.locale === 'en'  ? '--- Undefined ---'
                                          : '--- 指定無し ---');
      return result;
    }, // End of strUndefined() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    getLink(link) {
      if (link.includes('https://') || link.includes('http://')) {
        return link;
      } else {
        return `https://${link}`;
      } // End of if (link.includes('https://') || ...) {...}
    }, // End of getLink(link) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    ...mapActions({
      fetchProjectDetail: 'projects/fetchProjectDetail',
    }),
    // ---+--------+---------+---------+---------+---------+---------+---------E
    async fetchProjectDetailById() {
      let arrayHierarchy = [];
      try {
        const { id } = this.$route.params;
        await this.fetchProjectDetail(id);
        // --------+---------+---------+---------+---------+---------+---------E
        // カテゴリ・プロジェクト・シリーズ・エディションのコンボボックスの
        // 選択肢を作る。
        await getCategories(); // カテゴリ選択肢を作るためここで実行。
        this.arrayCreators = [{key: this.strUndefined(), value: 0}];
        this.arraySeries   = [{key: this.strUndefined(), value: 0}];
        this.arrayEditions = [{key: this.strUndefined(), value: 0}];
        // --------+---------+---------+---------+---------+---------+---------E
        this.category_id = 0; // コンボボックスの選択を「指定なし」にする。
        this.creator_id  = 0; // コンボボックスの選択を「指定なし」にする。
        this.series_id   = 0; // コンボボックスの選択を「指定なし」にする。
        this.edition_id  = 0; // コンボボックスの選択を「指定なし」にする。
        // --------+---------+---------+---------+---------+---------+---------E
        // alert(`this.projectDetail.hierarchy=${this.projectDetail.hierarchy}: fetchProjectDetailById`);
        if (!this.projectDetail.hierarchy) { return; } // hierarchy空で終了。
        // --------+---------+---------+---------+---------+---------+---------E
        // hierarchy に値が有る。
        arrayHierarchy = this.projectDetail.hierarchy.split('\\');
        // alert(`arrayHierarchy=${JSON.stringify(arrayHierarchy)}: fetchProjectDetailById`);
        if (!arrayHierarchy[0]) { return } // カテゴリ指定が無いので終了。
        // --------+---------+---------+---------+---------+---------+---------E
        // カテゴリ指定がある。
        this.category_id = this.objectCategoryNameEnId[arrayHierarchy[0]];
        // alert(`this.category_id=${this.category_id}: fetchProjectDetailById`);
        await this.getCreators(); // クリエイター選択肢を作るためここで実行。
        // --------+---------+---------+---------+---------+---------+---------E
        if (!arrayHierarchy[1]) { return } // クリエイター指定が無いので終了。
        // --------+---------+---------+---------+---------+---------+---------E
        // クリエイター指定がある。
        this.creator_id = this.objectCreatorNameEnId[arrayHierarchy[1]];
        // alert(`this.creator_id=${this.creator_id}: fetchProjectDetailById`);
        await this.getSeries(); // シリーズ選択肢を作るためここで実行。
        // --------+---------+---------+---------+---------+---------+---------E
        if (!arrayHierarchy[2]) { return } // シリーズ指定が無いので終了。
        // --------+---------+---------+---------+---------+---------+---------E
        // シリーズ指定がある。
        this.series_id = this.objectSeriesNameEnId[arrayHierarchy[2]];
        // alert(`this.series_id=${this.series_id}: fetchProjectDetailById`);
        await this.getEditions(); // エディション選択肢を作るためここで実行。
        // --------+---------+---------+---------+---------+---------+---------E
        if (!arrayHierarchy[3]) { return } // エディション指定が無いので終了。
        // --------+---------+---------+---------+---------+---------+---------E
        // エディション指定がある。
        this.edition_id = this.objectEditionNameEnId[arrayHierarchy[3]];
        //alert(`this.edition_id=${this.edition_id}: fetchProjectDetailById`);
      } catch (error) {
        console.debug({ error });
        UtilNotify.error(this.$notify, error);
        this.$router.push('/manage/projects');
      } // End of try {...} catch (error) {...}
    }, // End of async fetchProjectDetailById() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    async onSubmitForm() {
      try {
        const isValid = await this.$refs.observer.validate();
        console.debug({
          preSaleTimeError: this.preSaleTimeError,
          publicSaleTimeError: this.publicSaleTimeError,
          isValid,
        }); // End of console.debug({...})
        if (!isValid || this.preSaleTimeError || this.publicSaleTimeError) return;
        if (this.loading) return;
        this.loading = true;
        // --------+---------+---------+---------+---------+---------+---------E
        const form = {
          ...this.form,
          banner: this.form.banner?.file,
          symbol: this.form.symbol || 'T2WEB',
          thumbnail: this.form.thumbnail?.file,
          images: this.form.images?.file,
          json: this.form.json?.file,
          whitelist: this.form.whitelist?.file,
          presale_start_time: this.form.presale_start_time
            ? new BigNumber(this.form.presale_start_time).div(1000).toNumber()
            : null,
          presale_end_time: this.form.presale_end_time
            ? new BigNumber(this.form.presale_end_time).div(1000).toNumber()
            : null,
          publicsale_start_time: this.form.publicsale_start_time
            ? new BigNumber(this.form.publicsale_start_time).div(1000).toNumber()
            : null,
          publicsale_end_time: this.form.publicsale_end_time
            ? new BigNumber(this.form.publicsale_end_time).div(1000).toNumber()
            : null,
          website_url: this.getLink(this.form.website_url),
          twitter_url: this.getLink(this.form.twitter_url),
          reveal_thumbnail: this.form.can_reveal ? this.form.reveal_thumbnail?.file : undefined,
          presale_amount: Number(this.form.presale_amount || 0),
          publicsale_amount: Number(this.form.publicsale_amount || 0),
          publicsale_max_purchase: Number(this.form.publicsale_max_purchase || 0),
          presale_max_purchase: Number(this.form.presale_max_purchase || 0),
          // referral: this.form.referral?.file,
        }; // End of const form = {...}
        // alert(`form=${JSON.stringify(form)}: onSubmitForm`);
        const payload = new FormData();
        for (const property in form) {
          const data = form[property];
          if (data || data === 0) {
            payload.append(`${property}`, form[property]);
          }
        } // End of for (const property in form) {...}
        // alert([...payload.entries()]);
        // alert(`credit_payment=${form.credit_payment}, ` +
        //       `shipping_form=${form.shipping_form}, ` +
        //       `stores_btn=${form.stores_btn}, ` +
        //       `stores_url=${form.stores_url}, ` +
        //       `exchange_wallet_info=${form.exchange_wallet_info}, ` +
        //       `creditcard_url=${form.creditcard_url}`);
        const data = await editProject(this.projectDetail.id, payload);
        this.loading = false;
        // --------+---------+---------+---------+---------+---------+---------E
        if (data) {
          UtilNotify.success(this.$notify, { message: 'PROJECT_EDIT_SUCCESSFUL_NOTIFICATION' });
          this.$router.push('/manage/projects');
        }
      } catch (error) {
        this.loading = false;
        // --------+---------+---------+---------+---------+---------+---------E
        console.error(error);
        UtilNotify.error(this.$notify, error);
      } // End of try {...} catch (error) {...}
    }, // End of async onSubmitForm() {...}
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
::v-deep .input-number-custom input {
  @apply px-2 rounded-none;
  @apply bg-white text-black text-lg;
  @apply h-8;
  @apply disabled:cursor-not-allowed;
}

::v-deep .ant-input {
  @apply border-none rounded-sm text-black text-lg;
}

::v-deep .ant-select {
  @apply font-semibold text-black;
  &-selection {
    background-color: #fff;
    @apply text-lg font-semibold;
    border: none;
    border-radius: 0px;
    &__rendered {
      // .ant-select-selection-selected-value {
      //   @apply relative left-1/2;
      //   transform: translateX(-50%);
      // }
    }
  }
}

::v-deep .ant-checkbox-wrapper {
  @apply text-white text-xl;
  .ant-checkbox {
    span {
      @apply text-xl text-white;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      @apply border-white bg-white;
      &::after {
        @apply border-primary;
      }
    }
    &::after {
      @apply border border-solid border-primary;
    }
  }
}

.w-150px {
  width: 150px;
}
</style>
